<template>
  <default-layout>
    <div class="mx-auto max-w-6xl pt-4">
      <Account />
    </div>
  </default-layout>
</template>

<script>
import Account from '@/components/account/Account';

export default {
  components: {
    Account,
  },
};
</script>

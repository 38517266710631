var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("default-layout", [
    _c("div", { staticClass: "mx-auto max-w-6xl pt-4" }, [_c("Account")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }